/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Subtitle, Text, Button, Fullmap, FullmapWrap, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-l6ewrj --style3 --full mb--0" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/23336/1c9e37e8c1bb4698b29fc31d9cf8d927_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/23336/1c9e37e8c1bb4698b29fc31d9cf8d927_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/23336/1c9e37e8c1bb4698b29fc31d9cf8d927_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/23336/1c9e37e8c1bb4698b29fc31d9cf8d927_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/23336/1c9e37e8c1bb4698b29fc31d9cf8d927_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/23336/1c9e37e8c1bb4698b29fc31d9cf8d927_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/23336/1c9e37e8c1bb4698b29fc31d9cf8d927_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/23336/1c9e37e8c1bb4698b29fc31d9cf8d927_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" anim={null} style={{"maxWidth":1346,"paddingLeft":0,"paddingRight":0}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"3"} style={{"maxWidth":850}}>
              
              <Image style={{"maxWidth":258,"marginBottom":0}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} alt={""} src={"https://cdn.swbpg.com/o/23336/b3cf4f59a6ab4e17a17e6f6b21f73238.svg"}>
              </Image>

              <Title className="title-box title-box--style3" content={"Společně tvoříme Vaše nové začátky"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":109,"paddingBottom":88}} name={"sluzby"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" anim={""} animS={"3"} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2" style={{"maxWidth":"","paddingBottom":5,"paddingTop":0}} anim={"2"}>
              
              <Title className="title-box lh--14" style={{"marginBottom":25}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Stavíme mosty k vašim</span> vysněným nemovitostem</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 flex--stretch" style={{"maxWidth":""}} columns={"4"} fullscreen={false}>
            
            <ColumnWrapper className="--shape4 js-anim  --anim2 --center pb--16 pl--12 pr--12 pt--16" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image alt={"Ikonka"} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-1.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--22 w--500" style={{"marginTop":7}} content={"<a href=\"/nemovistinaprodej\">Prodej nemovitosti</a>"}>
              </Subtitle>

              <Text className="text-box fs--14 w--400 lh--16" style={{"marginTop":12}} content={"<span style=\"color: rgb(33, 33, 33);\">Nabízím kompletní servis a profesionální přístup pro maximálně efektivní a výhodný prodej vaší nemovitosti.</span>"}>
              </Text>

              <Text className="text-box fs--14 w--400 lh--16" style={{"marginTop":12}} content={"<span style=\"color: rgb(33, 33, 33);\"><a href=\"/chci-prodat\">zjistit více</a><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --center pb--16 pl--12 pr--12 pt--16" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image alt={"Ikonka"} src={"https://cdn.swbpg.com/o/g/Ikonky/Hotel/hotel-14.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--22 w--500" style={{"marginTop":7}} content={"<a href=\"/chci-pronajmout\">Pronájem nemovitosti</a>"}>
              </Subtitle>

              <Text className="text-box fs--14 w--400 lh--16" style={{"marginTop":12}} content={"Ušetřím vám čas a starosti při hledání spolehlivých nájemníků a zajistím hladký průběh celého pronájmu.<br>"}>
              </Text>

              <Text className="text-box fs--14 w--400 lh--16" style={{"marginTop":12}} content={"<span style=\"color: rgb(33, 33, 33);\"><a href=\"/chci-pronajmout\">zjistit více</a><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --center pb--16 pl--12 pr--12 pt--16" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image alt={"Ikonka"} src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/447466f0ca0249c3bd3d8ba8c5b74267.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--22 w--500" style={{"marginTop":7}} content={"<a href=\"/odhad-nemovitosti\">Odhad tržní ceny</a>"}>
              </Subtitle>

              <Text className="text-box fs--14 w--400 lh--14" style={{"marginTop":12}} content={"Poskytnu vám přesný a realistický odhad hodnoty vaší nemovitosti založený na aktuálních tržních datech a trendech.<br>"}>
              </Text>

              <Text className="text-box fs--14 w--400 lh--16" style={{"marginTop":12}} content={"<span style=\"color: rgb(33, 33, 33);\"><a href=\"/odhad-nemovitosti\">zjistit více</a><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --center pb--16 pl--12 pr--12 pt--16" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image alt={"Ikonka"} src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/5295b4c8c0d74a51b522ffb0650bcc1f.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--22 w--500" style={{"marginTop":7}} content={"<a href=\"/chci-hypoteku\">Hypoteční poradenství</a>"}>
              </Subtitle>

              <Text className="text-box fs--14 w--400 lh--16" style={{"marginTop":12}} content={"S osmiletými zkušenostmi vám pomohu najít nejvýhodnější hypotéku přizpůsobenou vašim potřebám.<br>"}>
              </Text>

              <Text className="text-box fs--14 w--400 lh--16" style={{"marginTop":12}} content={"<span style=\"color: rgb(33, 33, 33);\"><a href=\"/chci-hypoteku\">zjistit více</a><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--1 flex--center" anim={""} animS={"3"} style={{"marginTop":70}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --center" style={{"maxWidth":"","paddingBottom":5,"paddingTop":0}} anim={"2"}>
              
              <Text className="text-box fs--16" content={"Zaujaly vás mé služby, nebo máte nějaké otázky? Rád vám odpovím!\n\n\n\n\n\n\n"}>
              </Text>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape4 fs--16" style={{"marginTop":13}} content={"Kontaktujte mě"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"#1e1e1f","paddingTop":58,"paddingBottom":40}} name={"novinka"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --left" anim={"5"}>
              
              <Image className="--shape4" style={{"maxWidth":501}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37752/4df065f03c0941a7a2affa9269495779_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37752/4df065f03c0941a7a2affa9269495779_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37752/4df065f03c0941a7a2affa9269495779_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37752/4df065f03c0941a7a2affa9269495779_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37752/4df065f03c0941a7a2affa9269495779_s=2000x_.jpg 2000w"} alt={"realizace"} src={"https://cdn.swbpg.com/t/37752/4df065f03c0941a7a2affa9269495779_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --left" anim={"4"}>
              
              <Subtitle className="subtitle-box fs--16" content={"<span style=\"color: var(--color-custom-2); background-color: rgb(55, 109, 255);\">&nbsp;Nově přidáno&nbsp;</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--style2 fs--20 w--500" content={"<span style=\"color: var(--color-blend--85);\">Rodinný dům ve Velké Hraštici – Perfektní bydlení blízko Prahy</span><br>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2);\">S potěšením vám nabízíme ke koupi nádherný rodinný dům, který se nachází v malebné obci Velká Hraštice, jen 30 kilometrů od Prahy. Tento moderní dům, kolaudovaný v roce 2017, je v bezchybném technickém stavu a představuje ideální domov pro rodinu, která hledá komfortní a klidné bydlení.&nbsp;</span><span style=\"color: var(--color-blend--95);\">Tento dům čeká na vás, abyste si jej mohli nazvat svým novým domovem</span><br>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":42,"paddingTop":0}} content={"<span style=\"color: var(--color-custom-1);\"><a href=\"/detail\">Detail</a></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":89,"paddingBottom":0}} name={"prefo"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
              
              <Image style={{"maxWidth":269}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={""} alt={"Logo"} src={"https://cdn.swbpg.com/o/23336/b3cf4f59a6ab4e17a17e6f6b21f73238.svg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
              
              <Title className="title-box lh--14" style={{"marginBottom":19}} content={"Jsme Häusl&amp;Home Reality<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":442,"marginTop":0}} content={"pomáháme Vám najít nový domov a provést Vás cestou k nové životní etapě. Upřímnost a odborné jednání jsou pro nás na prvním místě. Chceme být Vašimi partnery během celého procesu, aby pro Vás byl co nejjednodušší a bezstarostný."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"e9jd34g191i"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <SeparateLine className="pb--10 pt--10" name={"f0lqvhbqnwn"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box w--500" style={{"maxWidth":""}} content={"Häusl&amp;Home Reality"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={" Ke kapslovně 2855/1 Praha 3 130 00\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"tel:+420 724 808 718\" style=\"color: var(--color-blend--05);\">+420 724 808 718\n</a>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"mailto:miroslav.hausl@hauslahome.cz\" style=\"color: var(--color-blend--05);\">miroslav.hausl@hauslahome.cz\n</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right" content={"<a href=\"/sluzby\">Služby</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/nemovistinaprodej\">Nemovitosti</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/kontakt\">Kontakt</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}